import React, { useState } from "react";
import "./App.css";

import "tachyons";

function App() {
  const [hidden, setHidden] = useState(true);

  return (
    <div className=" bg-black vh-100">
      {hidden ? (
        <main className="cf pa3 pa4-m pa5-l mw9 center  white  ">
          <div className="fr w-100">
            <div className="focus-in-contract">
              <h1 className="f2 f1-l lh-title mt0   gray hover-dark-green">
                GREY WALD
              </h1>
              <h3 className="f3  text-focus-in ">consulting</h3>
              <h3 className="f3  text-focus-in-1">development</h3>
              <h3 className="f3  text-focus-in-2">engineering</h3>
            </div>
          </div>
          <div className="f6 lh-copy fl w-100 mb4 white   css-typing">
            <p className="grow">
              <small className="fw6 ">
                <a
                  className="link white b hover-orange black "
                  href="mailto:contact@greywald.net?subject=Information Request&body= Hello!"
                >
                  contact@greywald.net
                </a>
              </small>
            </p>
            <p>+49 176 214 29 222</p>

            <p>Ulner Dreieck 10, 35392 Gießen, Germany</p>
          </div>
          <br />
          <br />
          <br />
          <br />
          <span
            className="black mt7 pt7"
            style={{ opacity: "1" }}
            onClick={() => setHidden(!hidden)}
          >
            .{" "}
          </span>
        </main>
      ) : (
        <img
          className="w5 "
          src="/callingCard.png"
          onClick={() => setHidden(!hidden)}
        />
      )}
    </div>
  );
}

export default App;
